// 
// user.scss
// Use this to write your custom SCSS
//

@import '../css/fonts.css';

body main header .logo-name {
  font-family: 'Zian';
  color: #e06665;
  font-size: 30px;
  letter-spacing: 2px;
}

body main header .logo-name:hover {
  font-family: 'Zian';
  color: #da4646;
  font-size: 30px;
  letter-spacing: 2px;
}

body footer .navbar-brand {
  font-family: 'Zian';
  color: #e06665 !important;
  font-size: 30px;
  letter-spacing: 2px;
}
