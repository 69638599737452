// 
// user-variables.scss
// Use this to override Bootstrap and Silicon variables
//

// Example of a variable override to change Silicon background color
// Remove the "//" to comment it in and see it in action!
// $body-bg: #12263F;

// $primary:                     #b71c1c !default;
// $secondary:                   #ffc107 !default;


$primary:                     #e06666 !default;
$secondary:                   #fdd547 !default;
